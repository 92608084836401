import './App.css';
import EventRegistration from './EventRegistration';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from 'react';
import AOS from 'aos';
import SLHome from "./SriLanka/Home/Home";
import SLAbout from "./SriLanka/About/About";
import SLEvents from "./SriLanka/Events&News/Events";
import SLUpcomingEvents from "./SriLanka/Events&News/UpcomingNews/UpcomingNews";
import SLBlog from "./SriLanka/Blog/Blog";
import SLContactUs from "./SriLanka/ContactUs/Contact";
import SLSolutions from "./SriLanka/Solutions/Solutions";
import SLPortal from "./SriLanka/Portal/Portal";
import SLCyberSecurity from "./SriLanka/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import SLAuthentication from "./SriLanka/Solutions/solutionVendors/Authentication/Authentication";
import SLSecurityManagement from "./SriLanka/Solutions/solutionVendors/Security/Security";
import SLPerimia from "./SriLanka/Solutions/solutionVendors/Perimia/Perimia";
import SLEndpointSecurity from "./SriLanka/Solutions/solutionVendors/Endpoint/Endpoin";
import SLNetworking from "./SriLanka/Solutions/solutionVendors/Networking/Networking";
import SLInfrastructure from "./SriLanka/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import SLServer from "./SriLanka/Solutions/solutionVendors/ServerBackup/ServerBackup";
import SLMicrosoft from "./SriLanka/Solutions/solutionVendors/Microsoft/Microsoft";
import SLIOT from "./SriLanka/Solutions/solutionVendors/IOT/IOT";
import SLUCC from "./SriLanka/Solutions/solutionVendors/UCC/Ucc";
import SLCpa from "./SriLanka/Solutions/solutionVendors/CPA/Cpa";
import SLAVIoT from "./SriLanka/Solutions/solutionVendors/AVIoT/AVIoT";
import SLFAQs from "./SriLanka/FAQs/FAQs";
import SLSingleVendor from "./SriLanka/SingleVendor/SingleVendor";
import SLCareers from "./SriLanka/Careers/Careers"


import KHHome from "./Cambodia/Components/Home/Home";
import KHAbout from "./Cambodia/Components/About/About";
import KHEvents from "./Cambodia/Components/Events&News/Events";
import KHUpcomingEvents from "./Cambodia/Components/Events&News/UpcomingNews/UpcomingNews";
import KHBlog from "./Cambodia/Components/Blog/Blog";
import KHContactUs from "./Cambodia/Components/ContactUs/Contact";
import KHSolutions from "./Cambodia/Components/Solutions/Solutions";
import KHPortal from "./Cambodia/Components/Portal/Portal";
import KHCyberSecurity from "./Cambodia/Components/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import KHAuthentication from "./Cambodia/Components/Solutions/solutionVendors/Authentication/Authentication";
import KHSecurityManagement from "./Cambodia/Components/Solutions/solutionVendors/Security/Security";
import KHPerimia from "./Cambodia/Components/Solutions/solutionVendors/Perimia/Perimia";
import KHEndpointSecurity from "./Cambodia/Components/Solutions/solutionVendors/Endpoint/Endpoin";
import KHNetworking from "./Cambodia/Components/Solutions/solutionVendors/Networking/Networking";
import KHInfrastructure from "./Cambodia/Components/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import KHServer from "./Cambodia/Components/Solutions/solutionVendors/ServerBackup/ServerBackup";
import KHMicrosoft from "./Cambodia/Components/Solutions/solutionVendors/Microsoft/Microsoft";

import KHAVIoT from "./Cambodia/Components/Solutions/solutionVendors/AVIoT/AVIoT";
import KHCPA from "./Cambodia/Components/Solutions/solutionVendors/CPA/CPA";
import KHIoT from "./Cambodia/Components/Solutions/solutionVendors/IOT/IOT";
import KHUCC from "./Cambodia/Components/Solutions/solutionVendors/UCC/UCC";

import KHSingleVendor from "./Cambodia/Components/SingleVendor/SingleVendor";
import KHSeagate from "./Cambodia/Components/Seagate/SeagateSinglePage";

import THHome from "./Thailand/Components/Home/Home";
import THAbout from "./Thailand/Components/About/About";
import THEvents from "./Thailand/Components/Events&News/Events";
import THUpcomingEvents from "./Thailand/Components/Events&News/UpcomingNews/UpcomingNews";
import THBlog from "./Thailand/Components/Blog/Blog";
import THContactUs from "./Thailand/Components/ContactUs/Contact";
import THSolutions from "./Thailand/Components/Solutions/Solutions";
import THPortal from "./Thailand/Components/Portal/Portal";
import THCyberSecurity from "./Thailand/Components/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import THAuthentication from "./Thailand/Components/Solutions/solutionVendors/Authentication/Authentication";
import THSecurityManagement from "./Thailand/Components/Solutions/solutionVendors/Security/Security";
import THInternetSecurity from "./Thailand/Components/Solutions/solutionVendors/Perimia/Perimia";
import THEndpointSecurity from "./Thailand/Components/Solutions/solutionVendors/Endpoint/Endpoin";
import THNetworking from "./Thailand/Components/Solutions/solutionVendors/Networking/Networking";
import THInfrastructure from "./Thailand/Components/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import THServer from "./Thailand/Components/Solutions/solutionVendors/ServerBackup/ServerBackup";

import THAVIoT from "./Thailand/Components/Solutions/solutionVendors/AVIoT/AVIoT";
import THCPA from "./Thailand/Components/Solutions/solutionVendors/CPA/CPA";
import THUCC from "./Thailand/Components/Solutions/solutionVendors/UCC/UCC";
import THIOT from "./Thailand/Components/Solutions/solutionVendors/IOT/IOT";

import THSingleVendor from "./Thailand/Components/SingleVendor/SingleVendor";

import AUHome from "./Australia/Home/Home";
import AUAbout from "./Australia/About/About";
import AUEvents from "./Australia/Events&News/Events";
import AUUpcomingEvents from "./Australia/Events&News/UpcomingNews/UpcomingNews";
import AUBlog from "./Australia/Blog/Blog";
import AUContactUs from "./Australia/ContactUs/Contact";
import AUSolutions from "./Australia/Solutions/Solutions";
import AUPortal from "./Australia/Portal/Portal";
import AUCyberSecurity from "./Australia/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import AUAuthentication from "./Australia/Solutions/solutionVendors/Authentication/Authentication";
import AUSecurityManagement from "./Australia/Solutions/solutionVendors/Security/Security";
import AUPerimia from "./Australia/Solutions/solutionVendors/Perimia/Perimia";
import AUEndpointSecurity from "./Australia/Solutions/solutionVendors/Endpoint/Endpoin";
import AUNetworking from "./Australia/Solutions/solutionVendors/Networking/Networking";
import AUInfrastructure from "./Australia/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import AUServer from "./Australia/Solutions/solutionVendors/ServerBackup/ServerBackup";
import AUMicrosoft from "./Australia/Solutions/solutionVendors/Microsoft/Microsoft";
import AUUCC from "./Australia/Solutions/solutionVendors/Ucc/Ucc";
import AUIOT from "./Australia/Solutions/solutionVendors/SmartIoT/SmartIoT";
import AUCPA from "./Australia/Solutions/solutionVendors/Accessories/Accessories";
import AUAVIoT from "./Australia/Solutions/solutionVendors/AVIoT/AVIoT";
import AUFAQs from "./Australia/FAQs/FAQs";
import AUSingleVendor from "./Australia/SingleVendor/SingleVendor";

import MUHome from "./Mauritius/Components/Home/Home";
import MUAbout from "./Mauritius/Components/About/About";
import MUEvents from "./Mauritius/Components/Events&News/Events";
import MUUpcomingEvents from "./Mauritius/Components/Events&News/UpcomingNews/UpcomingNews";
import MUBlog from "./Mauritius/Components/Blog/Blog";
import MUContactUs from "./Mauritius/Components/ContactUs/Contact";
import MUSolutions from "./Mauritius/Components/Solutions/Solutions";
import MUPortal from "./Mauritius/Components/Portal/Portal";
import MUCyberSecurity from "./Mauritius/Components/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import MUAuthentication from "./Mauritius/Components/Solutions/solutionVendors/Authentication/Authentication";
import MUSecurityManagement from "./Mauritius/Components/Solutions/solutionVendors/Security/Security";
import MUPerimia from "./Mauritius/Components/Solutions/solutionVendors/Perimia/Perimia";
import MUEndpointSecurity from "./Mauritius/Components/Solutions/solutionVendors/Endpoint/Endpoin";
import MUNetworking from "./Mauritius/Components/Solutions/solutionVendors/Networking/Networking";
import MUInfrastructure from "./Mauritius/Components/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import MUServer from "./Mauritius/Components/Solutions/solutionVendors/ServerBackup/ServerBackup";
import MUMicrosoft from "./Mauritius/Components/Solutions/solutionVendors/Microsoft/Microsoft";
import MUAVIoT from "./Mauritius/Components/Solutions/solutionVendors/AVIoT/AVIoT";
import MUCPA from "./Mauritius/Components/Solutions/solutionVendors/CPA/CPA";
import MUIOT from "./Mauritius/Components/Solutions/solutionVendors/IOT/IOT";
import MUUCC from "./Mauritius/Components/Solutions/solutionVendors/UCC/UCC";
import MUSingleVendor from "./Mauritius/Components/SingleVendor/SingleVendor";


import BrnHome from "./Brunai/Components/Home/Home";
import BrnAbout from "./Brunai/Components/About/About";
import BrnEvents from "./Brunai/Components/Events&News/Events";
import BrnUpcomingEvents from "./Brunai/Components/Events&News/UpcomingNews/UpcomingNews";
import BrnBlog from "./Brunai/Components/Blog/Blog";
import BrnContactUs from "./Brunai/Components/ContactUs/Contact";
import BrnSolutions from "./Brunai/Components/Solutions/Solutions";
import BrnPortal from "./Brunai/Components/Portal/Portal";
import BrnCyberSecurity from "./Brunai/Components/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import BrnAuthentication from "./Brunai/Components/Solutions/solutionVendors/Authentication/Authentication";
import BrnSecurityManagement from "./Brunai/Components/Solutions/solutionVendors/Security/Security";
import BrnInternetSecurity from "./Brunai/Components/Solutions/solutionVendors/Perimia/Perimia";
import BrnEndpointSecurity from "./Brunai/Components/Solutions/solutionVendors/Endpoint/Endpoin";
import BrnNetworking from "./Brunai/Components/Solutions/solutionVendors/Networking/Networking";
import BrnInfrastructure from "./Brunai/Components/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import BrnServer from "./Brunai/Components/Solutions/solutionVendors/ServerBackup/ServerBackup";
import BrnCPA from "./Brunai/Components/Solutions/solutionVendors/CPA/CPA";
import BrnIOT from "./Brunai/Components/Solutions/solutionVendors/IOT/IOT";
import BrnUCC from "./Brunai/Components/Solutions/solutionVendors/UCC/UCC";
import BrnSingleVendor from "./Brunai/Components/SingleVendor/SingleVendor";
import BrnMicrosoft from "./Brunai/Components/Solutions/solutionVendors/Microsoft/Microsoft";
import BrnAVIoT from "./Brunai/Components/Solutions/solutionVendors/AVIoT/AVIoT";


import NPLHome from "./Nepal/Components/Home/Home";
import NPLAbout from "./Nepal/Components/About/About";
import NPLEvents from "./Nepal/Components/Events&News/Events";
import NPLUpcomingEvents from "./Nepal/Components/Events&News/UpcomingNews/UpcomingNews";
import NPLBlog from "./Nepal/Components/Blog/Blog";
import NPLContactUs from "./Nepal/Components/ContactUs/Contact";
import NPLSolutions from "./Nepal/Components/Solutions/Solutions";
import NPLPortal from "./Nepal/Components/Portal/Portal";
import NPLCyberSecurity from "./Nepal/Components/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import NPLAuthentication from "./Nepal/Components/Solutions/solutionVendors/Authentication/Authentication";
import NPLSecurityManagement from "./Nepal/Components/Solutions/solutionVendors/Security/Security";
import NPLInternetSecurity from "./Nepal/Components/Solutions/solutionVendors/Perimia/Perimia";
import NPLEndpointSecurity from "./Nepal/Components/Solutions/solutionVendors/Endpoint/Endpoin";
import NPLNetworking from "./Nepal/Components/Solutions/solutionVendors/Networking/Networking";
import NPLInfrastructure from "./Nepal/Components/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import NPLServer from "./Nepal/Components/Solutions/solutionVendors/ServerBackup/ServerBackup";
import NPLAVIoT from "./Nepal/Components/Solutions/solutionVendors/AVIoT/AVIoT";
import NPLCPA from "./Nepal/Components/Solutions/solutionVendors/CPA/CPA";
import NPLIOT from "./Nepal/Components/Solutions/solutionVendors/IOT/IOT";
import NPLUCC from "./Nepal/Components/Solutions/solutionVendors/UCC/UCC";
import NPLSingleVendor from "./Nepal/Components/SingleVendor/SingleVendor";
import NPLMicrosoft from "./Nepal/Components/Solutions/solutionVendors/Microsoft/Microsoft";


import UAEHome from "./UAE/Home/Home";
import UAEAbout from "./UAE/About/About";
import UAEEvents from "./UAE/Events&News/Events";
import UAEUpcomingEvents from "./UAE/Events&News/UpcomingNews/UpcomingNews";
import UAEBlog from "./UAE/Blog/Blog";
import UAEContactUs from "./UAE/ContactUs/Contact";
import UAESolutions from "./UAE/Solutions/Solutions";
import UAEPortal from "./UAE/Portal/Portal";
import UAECyberSecurity from "./UAE/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import UAEAuthentication from "./UAE/Solutions/solutionVendors/Authentication/Authentication";
import UAESecurityManagement from "./UAE/Solutions/solutionVendors/Security/Security";
import UAEPerimia from "./UAE/Solutions/solutionVendors/Perimia/Perimia";
import UAEEndpointSecurity from "./UAE/Solutions/solutionVendors/Endpoint/Endpoin";
import UAENetworking from "./UAE/Solutions/solutionVendors/Networking/Networking";
import UAEInfrastructure from "./UAE/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import UAEServer from "./UAE/Solutions/solutionVendors/ServerBackup/ServerBackup";
import UAEMicrosoft from "./UAE/Solutions/solutionVendors/Microsoft/Microsoft";
import UAEIOT from "./UAE/Solutions/solutionVendors/IOT/IOT";
import UAEUCC from "./UAE/Solutions/solutionVendors/UCC/Ucc";
import UAECpa from "./UAE/Solutions/solutionVendors/CPA/Cpa";
import UAEAVIoT from "./UAE/Solutions/solutionVendors/AVIoT/AVIoT";
// import UAEFAQs from "./UAE/FAQs/FAQs";
import UAESingleVendor from "./UAE/SingleVendor/SingleVendor";
// import UAECareers from "./UAE/Careers/Careers"


import BGDHome from "./Bangladesh/Home/Home";
import BGDAbout from "./Bangladesh/About/About";
import BGDEvents from "./Bangladesh/Events&News/Events";
import BGDUpcomingEvents from "./Bangladesh/Events&News/UpcomingNews/UpcomingNews";
import BGDBlog from "./Bangladesh/Blog/Blog";
import BGDContactUs from "./Bangladesh/ContactUs/Contact";
import BGDSolutions from "./Bangladesh/Solutions/Solutions";
import BGDPortal from "./Bangladesh/Portal/Portal";
import BGDCyberSecurity from "./Bangladesh/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import BGDAuthentication from "./Bangladesh/Solutions/solutionVendors/Authentication/Authentication";
import BGDSecurityManagement from "./Bangladesh/Solutions/solutionVendors/Security/Security";
import BGDPerimia from "./Bangladesh/Solutions/solutionVendors/Perimia/Perimia";
import BGDEndpointSecurity from "./Bangladesh/Solutions/solutionVendors/Endpoint/Endpoin";
import BGDNetworking from "./Bangladesh/Solutions/solutionVendors/Networking/Networking";
import BGDInfrastructure from "./Bangladesh/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import BGDServer from "./Bangladesh/Solutions/solutionVendors/ServerBackup/ServerBackup";
import BGDMicrosoft from "./Bangladesh/Solutions/solutionVendors/Microsoft/Microsoft";
import BGDIOT from "./Bangladesh/Solutions/solutionVendors/IOT/IOT";
import BGDUCC from "./Bangladesh/Solutions/solutionVendors/UCC/Ucc";
import BGDCpa from "./Bangladesh/Solutions/solutionVendors/CPA/Cpa";
import BGDAVIoT from "./Bangladesh/Solutions/solutionVendors/AVIoT/AVIoT";
import BGDFAQs from "./Bangladesh/FAQs/FAQs";
import BGDSingleVendor from "./Bangladesh/SingleVendor/SingleVendor";
import BGDCareers from "./Bangladesh/Careers/Careers"



import SGHome from "./Singapore/Home/Home";
import SGAbout from "./Singapore/About/About";
import SGEvents from "./Singapore/Events&News/Events";
import SGUpcomingEvents from "./Singapore/Events&News/UpcomingNews/UpcomingNews";
import SGBlog from "./Singapore/Blog/Blog";
import SGContactUs from "./Singapore/ContactUs/Contact";
import SGSolutions from "./Singapore/Solutions/Solutions";
import SGPortal from "./Singapore/Portal/Portal";
import SGCyberSecurity from "./Singapore/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import SGAuthentication from "./Singapore/Solutions/solutionVendors/Authentication/Authentication";
import SGSecurityManagement from "./Singapore/Solutions/solutionVendors/Security/Security";
import SGPerimia from "./Singapore/Solutions/solutionVendors/Perimia/Perimia";
import SGEndpointSecurity from "./Singapore/Solutions/solutionVendors/Endpoint/Endpoin";
import SGNetworking from "./Singapore/Solutions/solutionVendors/Networking/Networking";
import SGInfrastructure from "./Singapore/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import SGServer from "./Singapore/Solutions/solutionVendors/ServerBackup/ServerBackup";
import SGMicrosoft from "./Singapore/Solutions/solutionVendors/Microsoft/Microsoft";
import SGIOT from "./Singapore/Solutions/solutionVendors/IOT/IOT";
import SGUCC from "./Singapore/Solutions/solutionVendors/UCC/Ucc";
import SGCpa from "./Singapore/Solutions/solutionVendors/CPA/Cpa";
import SGAVIoT from "./Singapore/Solutions/solutionVendors/AVIoT/AVIoT";
import SGFAQs from "./Singapore/FAQs/FAQs";
import SGSingleVendor from "./Singapore/SingleVendor/SingleVendor";
import SGCareers from "./Singapore/Careers/Careers"



import MDVHome from "./Maldives/Home/Home";
import MDVAbout from "./Maldives/About/About";
import MDVEvents from "./Maldives/Events&News/Events";
import MDVUpcomingEvents from "./Maldives/Events&News/UpcomingNews/UpcomingNews";
import MDVBlog from "./Maldives/Blog/Blog";
import MDVContactUs from "./Maldives/ContactUs/Contact";
import MDVSolutions from "./Maldives/Solutions/Solutions";
import MDVPortal from "./Maldives/Portal/Portal";
import MDVCyberSecurity from "./Maldives/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import MDVAuthentication from "./Maldives/Solutions/solutionVendors/Authentication/Authentication";
import MDVSecurityManagement from "./Maldives/Solutions/solutionVendors/Security/Security";
import MDVPerimia from "./Maldives/Solutions/solutionVendors/Perimia/Perimia";
import MDVEndpointSecurity from "./Maldives/Solutions/solutionVendors/Endpoint/Endpoin";
import MDVNetworking from "./Maldives/Solutions/solutionVendors/Networking/Networking";
import MDVInfrastructure from "./Maldives/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import MDVServer from "./Maldives/Solutions/solutionVendors/ServerBackup/ServerBackup";
import MDVMicrosoft from "./Maldives/Solutions/solutionVendors/Microsoft/Microsoft";
import MDVIOT from "./Maldives/Solutions/solutionVendors/IOT/IOT";
import MDVUCC from "./Maldives/Solutions/solutionVendors/UCC/Ucc";
import MDVCpa from "./Maldives/Solutions/solutionVendors/CPA/Cpa";
import MDVAVIoT from "./Maldives/Solutions/solutionVendors/AVIoT/AVIoT";
import MDVFAQs from "./Maldives/FAQs/FAQs";
import MDVSingleVendor from "./Maldives/SingleVendor/SingleVendor";
import MDVCareers from "./Maldives/Careers/Careers"


import INDHome from "./India/Home/Home";
import INDAbout from "./India/About/About";
import INDEvents from "./India/Events&News/Events";
import INDUpcomingEvents from "./India/Events&News/UpcomingNews/UpcomingNews";
import INDBlog from "./India/Blog/Blog";
import INDContactUs from "./India/ContactUs/Contact";
import INDSolutions from "./India/Solutions/Solutions";
import INDPortal from "./India/Portal/Portal";
import INDCyberSecurity from "./India/Solutions/solutionVendors/CyberSecurity/CyberSecurity";
import INDAuthentication from "./India/Solutions/solutionVendors/Authentication/Authentication";
import INDSecurityManagement from "./India/Solutions/solutionVendors/Security/Security";
import INDPerimia from "./India/Solutions/solutionVendors/Perimia/Perimia";
import INDEndpointSecurity from "./India/Solutions/solutionVendors/Endpoint/Endpoin";
import INDNetworking from "./India/Solutions/solutionVendors/Networking/Networking";
import INDInfrastructure from "./India/Solutions/solutionVendors/Infrastructure/InfrastructureMonitoring";
import INDServer from "./India/Solutions/solutionVendors/ServerBackup/ServerBackup";
import INDMicrosoft from "./India/Solutions/solutionVendors/Microsoft/Microsoft";
import INDIOT from "./India/Solutions/solutionVendors/IOT/IOT";
import INDUCC from "./India/Solutions/solutionVendors/UCC/Ucc";
import INDCpa from "./India/Solutions/solutionVendors/CPA/Cpa";
import INDAVIoT from "./India/Solutions/solutionVendors/AVIoT/AVIoT";
import INDFAQs from "./India/FAQs/FAQs";
import INDSingleVendor from "./India/SingleVendor/SingleVendor";
import INDCareers from "./India/Careers/Careers"



import GLBHome from "./GlobalNew/Home/Home";
import GLBContactUs from "./GlobalNew/ContactUs/Contact";
import GLBSolutions from "./GlobalNew/Solutions/Solution";
import GLBRegional from "./GlobalNew/RegionalPresence/Regions"
import GLBAboutUs from "./GlobalNew/AboutusGlobal/GlobalAbout"



function App() {

    AOS.init();

    return (
        <Router>
            <>
                <Routes>
                    {/* Event Registration rout */}

                    <Route path="/eventregister" exact element={<EventRegistration />} />


                    {/* Global */}
                    {/* <Route path="/" exact element={<GLBHome />} /> */}
                    <Route path="/Global" exact element={<GLBHome />} />
                    <Route path="/Global/ContactUs" exact element={<GLBContactUs />} />
                    <Route path="/Global/Solutions" exact element={<GLBSolutions />} />
                    <Route path="/Global/RegionalPresence" exact element={<GLBRegional />} />
                    <Route path="/Global/About" exact element={<GLBAboutUs />} />



                    {/*Sri Lanka*/}
                    <Route path="/" exact element={<SLHome />} />
                    <Route path="/SL" exact element={<SLHome />} />
                    <Route path="/SL/About" exact element={<SLAbout />} />
                    <Route path="/SL/Events&News" exact element={<SLEvents />} />
                    <Route path="/SL/UpcomingNews" exact element={<SLUpcomingEvents />} />
                    <Route path="/SL/Blog" exact element={<SLBlog />} />
                    <Route path="/SL/ContactUs" exact element={<SLContactUs />} />
                    <Route path="/SL/Solutions" exact element={<SLSolutions />} />
                    <Route path="/SL/Portal" exact element={<SLPortal />} />

                    <Route path="/SL/Solutions/CyberSecurity" element={<SLCyberSecurity />} />
                    <Route path="/SL/Solutions/Authentication" element={<SLAuthentication />} />
                    <Route path="/SL/Solutions/SecurityManagement" element={<SLSecurityManagement />} />
                    <Route path="/SL/Solutions/Perimia" element={<SLPerimia />} />
                    <Route path="/SL/Solutions/EndpointSecurity" element={<SLEndpointSecurity />} />
                    <Route path="/SL/Solutions/Networking" element={<SLNetworking />} />
                    <Route path="/SL/Solutions/Infrastructure" element={<SLInfrastructure />} />
                    <Route path="/SL/Solutions/Server" element={<SLServer />} />
                    <Route path="/SL/Solutions/Microsoft" element={<SLMicrosoft />} />
                    <Route path="/SL/Solutions/IOT" element={<SLIOT />} />
                    <Route path="/SL/Solutions/UCC" element={<SLUCC />} />
                    <Route path="/SL/Solutions/Cpa" element={<SLCpa />} />
                    <Route path="/SL/Solutions/AVIoT" element={<SLAVIoT />} />
                    <Route path="/SL/FAQs" element={<SLFAQs />} />
                    <Route path="/SL/Solutions/Authentication/:vendorName" element={<SLSingleVendor />} />
                    <Route path="/SL/Solutions/CyberSecurity/:vendorName" element={<SLSingleVendor />} />
                    <Route path="/SL/Solutions/Endpoin/:vendorName" element={<SLSingleVendor />} />
                    <Route path="/SL/Solutions/InfrastructureMonitoring/:vendorName" element={<SLSingleVendor />} />
                    <Route path="/SL/Solutions/Networking/:vendorName" element={<SLSingleVendor />} />
                    <Route path="/SL/Solutions/Perimia/:vendorName" element={<SLSingleVendor />} />
                    <Route path="/SL/Solutions/Security/:vendorName" element={<SLSingleVendor />} />
                    <Route path="/SL/Solutions/ServerBackup/:vendorName" element={<SLSingleVendor />} />
                    <Route path="/SL/Solutions/IOT/:vendorName" element={<SLSingleVendor />} />
                    <Route path="/SL/Solutions/UCC/:vendorName" element={<SLSingleVendor />} />
                    <Route path="/SL/Solutions/Cpa/:vendorName" element={<SLSingleVendor />} />
                    <Route path="/SL/Solutions/AVIoT/:vendorName" element={<SLSingleVendor />} />
                   

                    <Route path="/SL/Careers" element={<SLCareers />} />

                    {/*    Cambodia*/}
                    <Route path="/kh" exact Component={KHHome}></Route>
                    <Route path="/kh/About" exact Component={KHAbout}></Route>
                    <Route path="/kh/Events&News" exact element={<KHEvents />}></Route>
                    <Route path="/kh/UpcomingNews" exact element={<KHUpcomingEvents />}></Route>
                    <Route path="/kh/Blog" exact element={<KHBlog />}></Route>
                    <Route path="/kh/ContactUs" exact Component={KHContactUs}></Route>
                    <Route path="/kh/Solutions" exact Component={KHSolutions}></Route>
                    <Route path="/kh/Portal" exact Component={KHPortal}></Route>
                    <Route path="/kh/Seagate" element={<KHSeagate />} />

                    <Route path="/kh/Solutions/CyberSecurity" element={<KHCyberSecurity />} />
                    <Route path="/kh/Solutions/Authentication" element={<KHAuthentication />} />
                    <Route path="/kh/Solutions/SecurityManagement" element={<KHSecurityManagement />} />
                    <Route path="/kh/Solutions/Perimia" element={<KHPerimia />} />
                    <Route path="/kh/Solutions/EndpointSecurity" element={<KHEndpointSecurity />} />
                    <Route path="/kh/Solutions/Networking" element={<KHNetworking />} />
                    <Route path="/kh/Solutions/Infrastructure" element={<KHInfrastructure />} />
                    <Route path="/kh/Solutions/Server" element={<KHServer />} />
                    <Route path="/kh/Solutions/Microsoft" element={<KHMicrosoft />} />
                    <Route path="/kh/Solutions/AVIoT" element={<KHAVIoT />} />
                    <Route path="/kh/Solutions/Accessories" element={<KHCPA />} />
                    <Route path="/kh/Solutions/IOT" element={<KHIoT />} />
                    <Route path="/kh/Solutions/UCC" element={<KHUCC />} />
                    <Route path="/kh/Solutions/Authentication/:vendorName" element={<KHSingleVendor />} />
                    <Route path="/kh/Solutions/CyberSecurity/:vendorName" element={<KHSingleVendor />} />
                    <Route path="/kh/Solutions/Endpoin/:vendorName" element={<KHSingleVendor />} />
                    <Route path="/kh/Solutions/InfrastructureMonitoring/:vendorName" element={<KHSingleVendor />} />
                    <Route path="/kh/Solutions/Networking/:vendorName" element={<KHSingleVendor />} />
                    <Route path="/kh/Solutions/Perimia/:vendorName" element={<KHSingleVendor />} />
                    <Route path="/kh/Solutions/Security/:vendorName" element={<KHSingleVendor />} />
                    <Route path="/kh/Solutions/ServerBackup/:vendorName" element={<KHSingleVendor />} />
                    <Route path="/kh/Solutions/AVIoT/:vendorName" element={<KHSingleVendor />} />
                    <Route path="/kh/Solutions/IOT/:vendorName" element={<KHSingleVendor />} />
                    <Route path="/kh/Solutions/UCC/:vendorName" element={<KHSingleVendor />} />

                    {/*    Thailand*/}
                    <Route path="/TH" exact Component={THHome}></Route>
                    <Route path="/TH/About" exact Component={THAbout}></Route>
                    <Route path="/TH/Events&News" exact element={<THEvents />}></Route>
                    <Route path="/TH/UpcomingNews" exact element={<THUpcomingEvents />}></Route>
                    <Route path="/TH/Blog" exact element={<THBlog />}></Route>
                    <Route path="/TH/ContactUs" exact Component={THContactUs}></Route>
                    <Route path="/TH/Solutions" exact Component={THSolutions}></Route>
                    <Route path="/TH/Portal" exact Component={THPortal}></Route>

                    <Route path="/TH/Solutions/CyberSecurity" element={<THCyberSecurity />} />
                    <Route path="/TH/Solutions/Authentication" element={<THAuthentication />} />
                    <Route path="/TH/Solutions/SecurityManagement" element={<THSecurityManagement />} />
                    <Route path="/TH/Solutions/InternetSecurity" element={<THInternetSecurity />} />
                    <Route path="/TH/Solutions/EndpointSecurity" element={<THEndpointSecurity />} />
                    <Route path="/TH/Solutions/Networking" element={<THNetworking />} />
                    <Route path="/TH/Solutions/Infrastructure" element={<THInfrastructure />} />
                    <Route path="/TH/Solutions/Server" element={<THServer />} />

                    <Route path="/TH/Solutions/AVIoT" element={<THAVIoT />} />
                    <Route path="/TH/Solutions/CPA" element={<THCPA />} />
                    <Route path="/TH/Solutions/UCC" element={<THUCC />} />
                    <Route path="/TH/Solutions/IOT" element={<THIOT />} />

                    <Route path="/TH/Solutions/Authentication/:vendorName" element={<THSingleVendor />} />
                    <Route path="/TH/Solutions/CyberSecurity/:vendorName" element={<THSingleVendor />} />
                    <Route path="/TH/Solutions/Endpoin/:vendorName" element={<THSingleVendor />} />
                    <Route path="/TH/Solutions/InfrastructureMonitoring/:vendorName" element={<THSingleVendor />} />
                    <Route path="/TH/Solutions/Networking/:vendorName" element={<THSingleVendor />} />
                    <Route path="/TH/Solutions/Perimia/:vendorName" element={<THSingleVendor />} />
                    <Route path="/TH/Solutions/Security/:vendorName" element={<THSingleVendor />} />
                    <Route path="/TH/Solutions/ServerBackup/:vendorName" element={<THSingleVendor />} />
                    <Route path="/TH/Solutions/AVIoT/:vendorName" element={<THSingleVendor />} />
                    <Route path="/TH/Solutions/CPA/:vendorName" element={<THSingleVendor />} />
                    <Route path="/TH/Solutions/UCC/:vendorName" element={<THSingleVendor />} />
                    <Route path="/TH/Solutions/IOT/:vendorName" element={<THSingleVendor />} />

                    {/*Australia*/}

                    <Route path="/AU" exact element={<AUHome />} />
                    <Route path="/AU/About" exact element={<AUAbout />} />
                    <Route path="/AU/Events&News" exact element={<AUEvents />} />
                    <Route path="/AU/UpcomingNews" exact element={<AUUpcomingEvents />} />
                    <Route path="/AU/Blog" exact element={<AUBlog />} />
                    <Route path="/AU/ContactUs" exact element={<AUContactUs />} />
                    <Route path="/AU/Solutions" exact element={<AUSolutions />} />
                    <Route path="/AU/Portal" exact element={<AUPortal />} />

                    <Route path="/AU/Solutions/CyberSecurity" element={<AUCyberSecurity />} />
                    <Route path="/AU/Solutions/Authentication" element={<AUAuthentication />} />
                    <Route path="/AU/Solutions/SecurityManagement" element={<AUSecurityManagement />} />
                    <Route path="/AU/Solutions/Perimia" element={<AUPerimia />} />
                    <Route path="/AU/Solutions/EndpointSecurity" element={<AUEndpointSecurity />} />
                    <Route path="/AU/Solutions/Networking" element={<AUNetworking />} />
                    <Route path="/AU/Solutions/Infrastructure" element={<AUInfrastructure />} />
                    <Route path="/AU/Solutions/Server" element={<AUServer />} />
                    <Route path="/AU/Solutions/Microsoft" element={<AUMicrosoft />} />
                    <Route path="/AU/Solutions/UCC" element={<AUUCC />} />
                    <Route path="/AU/Solutions/Accessories" element={<AUCPA />} />
                    <Route path="/AU/Solutions/AVIoT" element={<AUAVIoT />} />
                    <Route path="/AU/Solutions/IOT" element={<AUIOT />} />
                    <Route path="/AU/FAQs" element={<AUFAQs />} />
                    <Route path="/AU/Solutions/Authentication/:vendorName" element={<AUSingleVendor />} />
                    <Route path="/AU/Solutions/CyberSecurity/:vendorName" element={<AUSingleVendor />} />
                    <Route path="/AU/Solutions/Endpoin/:vendorName" element={<AUSingleVendor />} />
                    <Route path="/AU/Solutions/InfrastructureMonitoring/:vendorName" element={<AUSingleVendor />} />
                    <Route path="/AU/Solutions/Networking/:vendorName" element={<AUSingleVendor />} />
                    <Route path="/AU/Solutions/Perimia/:vendorName" element={<AUSingleVendor />} />
                    <Route path="/AU/Solutions/Security/:vendorName" element={<AUSingleVendor />} />
                    <Route path="/AU/Solutions/ServerBackup/:vendorName" element={<AUSingleVendor />} />
                    <Route path="/AU/Solutions/UCC/:vendorName" element={<AUSingleVendor />} />
                    <Route path="/AU/Solutions/Accessories/:vendorName" element={<AUSingleVendor />} />
                    <Route path="/AU/Solutions/AVIoT/:vendorName" element={<AUSingleVendor />} />
                    <Route path="/AU/Solutions/IOT/:vendorName" element={<AUSingleVendor />} />

                    {/* Mauritius*/}
                    <Route path="/MU" exact Component={MUHome}></Route>
                    <Route path="/MU/About" exact Component={MUAbout}></Route>
                    <Route path="/MU/Events&News" exact element={<MUEvents />}></Route>
                    <Route path="/MU/UpcomingNews" exact element={<MUUpcomingEvents />}></Route>
                    <Route path="/MU/Blog" exact element={<MUBlog />}></Route>
                    <Route path="/MU/ContactUs" exact Component={MUContactUs}></Route>
                    <Route path="/MU/Solutions" exact Component={MUSolutions}></Route>
                    <Route path="/MU/Portal" exact Component={MUPortal}></Route>

                    <Route path="/MU/Solutions/CyberSecurity" element={<MUCyberSecurity />} />
                    <Route path="/MU/Solutions/Authentication" element={<MUAuthentication />} />
                    <Route path="/MU/Solutions/SecurityManagement" element={<MUSecurityManagement />} />
                    <Route path="/MU/Solutions/Perimia" element={<MUPerimia />} />
                    <Route path="/MU/Solutions/EndpointSecurity" element={<MUEndpointSecurity />} />
                    <Route path="/MU/Solutions/Networking" element={<MUNetworking />} />
                    <Route path="/MU/Solutions/Infrastructure" element={<MUInfrastructure />} />
                    <Route path="/MU/Solutions/Server" element={<MUServer />} />
                    <Route path="/MU/Solutions/Microsoft" element={<MUMicrosoft />} />
                    <Route path="/MU/Solutions/AVIoT" element={<MUAVIoT />} />
                    <Route path="/MU/Solutions/Accessories" element={<MUCPA />} />
                    <Route path="/MU/Solutions/IOT" element={<MUIOT />} />
                    <Route path="/MU/Solutions/UCC" element={<MUUCC />} />
                    <Route path="/MU/Solutions/Authentication/:vendorName" element={<MUSingleVendor />} />
                    <Route path="/MU/Solutions/CyberSecurity/:vendorName" element={<MUSingleVendor />} />
                    <Route path="/MU/Solutions/Endpoin/:vendorName" element={<MUSingleVendor />} />
                    <Route path="/MU/Solutions/InfrastructureMonitoring/:vendorName" element={<MUSingleVendor />} />
                    <Route path="/MU/Solutions/Networking/:vendorName" element={<MUSingleVendor />} />
                    <Route path="/MU/Solutions/Perimia/:vendorName" element={<MUSingleVendor />} />
                    <Route path="/MU/Solutions/Security/:vendorName" element={<MUSingleVendor />} />
                    <Route path="/MU/Solutions/ServerBackup/:vendorName" element={<MUSingleVendor />} />
                    <Route path="/MU/Solutions/AVIoT/:vendorName" element={<MUSingleVendor />} />
                    <Route path="/MU/Solutions/Accessories/:vendorName" element={<MUSingleVendor />} />
                    <Route path="/MU/Solutions/IOT/:vendorName" element={<MUSingleVendor />} />
                    <Route path="/MU/Solutions/UCC/:vendorName" element={<MUSingleVendor />} />


                    {/*    Brunei*/}
                    <Route path="/brn" exact Component={BrnHome}></Route>
                    <Route path="/brn/About" exact Component={BrnAbout}></Route>
                    <Route path="/brn/Events&News" exact element={<BrnEvents />}></Route>
                    <Route path="/brn/UpcomingNews" exact element={<BrnUpcomingEvents />}></Route>
                    <Route path="/brn/Blog" exact element={<BrnBlog />}></Route>
                    <Route path="/brn/ContactUs" exact Component={BrnContactUs}></Route>
                    <Route path="/brn/Solutions" exact Component={BrnSolutions}></Route>
                    <Route path="/brn/Portal" exact Component={BrnPortal}></Route>

                    <Route path="/brn/Solutions/CyberSecurity" element={<BrnCyberSecurity />} />
                    <Route path="/brn/Solutions/Authentication" element={<BrnAuthentication />} />
                    <Route path="/brn/Solutions/SecurityManagement" element={<BrnSecurityManagement />} />
                    <Route path="/brn/Solutions/InternetSecurity" element={<BrnInternetSecurity />} />
                    <Route path="/brn/Solutions/EndpointSecurity" element={<BrnEndpointSecurity />} />
                    <Route path="/brn/Solutions/Networking" element={<BrnNetworking />} />
                    <Route path="/brn/Solutions/Infrastructure" element={<BrnInfrastructure />} />
                    <Route path="/brn/Solutions/Server" element={<BrnServer />} />
                    <Route path="/brn/Solutions/Microsoft" element={<BrnMicrosoft />} />
                    <Route path="/brn/Solutions/AVIoT" element={<BrnAVIoT />} />
                    <Route path="/brn/Solutions/CPA" element={<BrnCPA />} />
                    <Route path="/brn/Solutions/IOT" element={<BrnIOT />} />
                    <Route path="/brn/Solutions/UCC" element={<BrnUCC />} />

                    <Route path="/brn/Solutions/Authentication/:vendorName" element={<BrnSingleVendor />} />
                    <Route path="/brn/Solutions/CyberSecurity/:vendorName" element={<BrnSingleVendor />} />
                    <Route path="/brn/Solutions/Endpoin/:vendorName" element={<BrnSingleVendor />} />
                    <Route path="/brn/Solutions/InfrastructureMonitoring/:vendorName" element={<BrnSingleVendor />} />
                    <Route path="/brn/Solutions/Networking/:vendorName" element={<BrnSingleVendor />} />
                    <Route path="/brn/Solutions/Perimia/:vendorName" element={<BrnSingleVendor />} />
                    <Route path="/brn/Solutions/Security/:vendorName" element={<BrnSingleVendor />} />
                    <Route path="/brn/Solutions/ServerBackup/:vendorName" element={<BrnSingleVendor />} />
                    <Route path="/brn/Solutions/AVIoT/:vendorName" element={<BrnSingleVendor />} />
                    <Route path="/brn/Solutions/CPA/:vendorName" element={<BrnSingleVendor />} />
                    <Route path="/brn/Solutions/IOT/:vendorName" element={<BrnSingleVendor />} />
                    <Route path="/brn/Solutions/UCC/:vendorName" element={<BrnSingleVendor />} />

                    {/* Nepal*/}
                    <Route path="/NPL" exact Component={NPLHome}></Route>
                    <Route path="/NPL/About" exact Component={NPLAbout}></Route>
                    <Route path="/NPL/Events&News" exact element={<NPLEvents />}></Route>
                    <Route path="/NPL/UpcomingNews" exact element={<NPLUpcomingEvents />}></Route>
                    <Route path="/NPL/Blog" exact element={<NPLBlog />}></Route>
                    <Route path="/NPL/ContactUs" exact Component={NPLContactUs}></Route>
                    <Route path="/NPL/Solutions" exact Component={NPLSolutions}></Route>
                    <Route path="/NPL/Portal" exact Component={NPLPortal}></Route>

                    <Route path="/NPL/Solutions/CyberSecurity" element={<NPLCyberSecurity />} />
                    <Route path="/NPL/Solutions/Authentication" element={<NPLAuthentication />} />
                    <Route path="/NPL/Solutions/SecurityManagement" element={<NPLSecurityManagement />} />
                    <Route path="/NPL/Solutions/InternetSecurity" element={<NPLInternetSecurity />} />
                    <Route path="/NPL/Solutions/EndpointSecurity" element={<NPLEndpointSecurity />} />
                    <Route path="/NPL/Solutions/Networking" element={<NPLNetworking />} />
                    <Route path="/NPL/Solutions/Infrastructure" element={<NPLInfrastructure />} />
                    <Route path="/NPL/Solutions/Server" element={<NPLServer />} />
                    <Route path="/NPL/Solutions/Authentication/:vendorName" element={<NPLSingleVendor />} />
                    <Route path="/NPL/Solutions/CyberSecurity/:vendorName" element={<NPLSingleVendor />} />
                    <Route path="/NPL/Solutions/Endpoin/:vendorName" element={<NPLSingleVendor />} />
                    <Route path="/NPL/Solutions/Microsoft" element={<NPLMicrosoft />} />
                    <Route path="/NPL/Solutions/AVIoT" element={<NPLAVIoT />} />
                    <Route path="/NPL/Solutions/IOT" element={<NPLIOT />} />
                    <Route path="/NPL/Solutions/UCC" element={<NPLUCC />} />
                    <Route path="/NPL/Solutions/CPA" element={<NPLCPA />} />
                    <Route path="/NPL/Solutions/InfrastructureMonitoring/:vendorName" element={<NPLSingleVendor />} />
                    <Route path="/NPL/Solutions/Networking/:vendorName" element={<NPLSingleVendor />} />
                    <Route path="/NPL/Solutions/Perimia/:vendorName" element={<NPLSingleVendor />} />
                    <Route path="/NPL/Solutions/Security/:vendorName" element={<NPLSingleVendor />} />
                    <Route path="/NPL/Solutions/ServerBackup/:vendorName" element={<NPLSingleVendor />} />
                    <Route path="/NPL/Solutions/AVIoT/:vendorName" element={<NPLSingleVendor />} />
                    <Route path="/NPL/Solutions/CPA/:vendorName" element={<NPLSingleVendor />} />
                    <Route path="/NPL/Solutions/UCC/:vendorName" element={<NPLSingleVendor />} />
                    <Route path="/NPL/Solutions/IOT/:vendorName" element={<NPLSingleVendor />} />


                    {/*New Zealand*/}

                    {/* <Route path="/NZ" exact element={<NZHome />} />
                    <Route path="/NZ/About" exact element={<NZAbout />} />
                    <Route path="/NZ/Events&News" exact element={<NZEvents />} />
                    <Route path="/NZ/UpcomingNews" exact element={<NZUpcomingEvents />} />
                    <Route path="/NZ/Blog" exact element={<NZBlog />} />
                    <Route path="/NZ/ContactUs" exact element={<NZContactUs />} />
                    <Route path="/NZ/Solutions" exact element={<NZSolutions />} />
                    <Route path="/NZ/Portal" exact element={<NZPortal />} />

                    <Route path="/NZ/Solutions/CyberSecurity" element={<NZCyberSecurity />} />
                    <Route path="/NZ/Solutions/Authentication" element={<NZAuthentication />} />
                    <Route path="/NZ/Solutions/SecurityManagement" element={<NZSecurityManagement />} />
                    <Route path="/NZ/Solutions/Perimia" element={<NZPerimia />} />
                    <Route path="/NZ/Solutions/EndpointSecurity" element={<NZEndpointSecurity />} />
                    <Route path="/NZ/Solutions/Networking" element={<NZNetworking />} />
                    <Route path="/NZ/Solutions/Infrastructure" element={<NZInfrastructure />} />
                    <Route path="/NZ/Solutions/Server" element={<NZServer />} />
                    <Route path="/NZ/Solutions/Microsoft" element={<NZMicrosoft />} />
                    <Route path="/NZ/FAQs" element={<NZFAQs />} />
                    <Route path="/NZ/Solutions/Authentication/:vendorName" element={<NZSingleVendor />} />
                    <Route path="/NZ/Solutions/CyberSecurity/:vendorName" element={<NZSingleVendor />} />
                    <Route path="/NZ/Solutions/Endpoin/:vendorName" element={<NZSingleVendor />} />
                    <Route path="/NZ/Solutions/InfrastructureMonitoring/:vendorName" element={<NZSingleVendor />} />
                    <Route path="/NZ/Solutions/Networking/:vendorName" element={<NZSingleVendor />} />
                    <Route path="/NZ/Solutions/Perimia/:vendorName" element={<NZSingleVendor />} />
                    <Route path="/NZ/Solutions/Security/:vendorName" element={<NZSingleVendor />} />
                    <Route path="/NZ/Solutions/ServerBackup/:vendorName" element={<NZSingleVendor />} /> */}


                    <Route path="/uae" exact Component={UAEHome}></Route>
                    <Route path="/uae/About" exact Component={UAEAbout}></Route>
                    <Route path="/uae/Events&News" exact element={<UAEEvents />}></Route>
                    <Route path="/uae/UpcomingNews" exact element={<UAEUpcomingEvents />}></Route>
                    <Route path="/uae/Blog" exact element={<UAEBlog />}></Route>
                    <Route path="/uae/ContactUs" exact Component={UAEContactUs}></Route>
                    <Route path="/uae/Solutions" exact Component={UAESolutions}></Route>
                    <Route path="/uae/Portal" exact Component={UAEPortal}></Route>
                    <Route path="/uae/Solutions/CyberSecurity" element={<UAECyberSecurity />} />
                    <Route path="/uae/Solutions/Authentication" element={<UAEAuthentication />} />
                    <Route path="/uae/Solutions/SecurityManagement" element={<UAESecurityManagement />} />
                    <Route path="/uae/Solutions/Perimia" element={<UAEPerimia />} />
                    <Route path="/uae/Solutions/EndpointSecurity" element={<UAEEndpointSecurity />} />
                    <Route path="/uae/Solutions/Networking" element={<UAENetworking />} />
                    <Route path="/uae/Solutions/Infrastructure" element={<UAEInfrastructure />} />
                    <Route path="/uae/Solutions/Server" element={<UAEServer />} />
                    <Route path="/uae/Solutions/Microsoft" element={<UAEMicrosoft />} />
                    <Route path="/uae/Solutions/AVIoT" element={<UAEAVIoT />} />
                    <Route path="/uae/Solutions/Accessories" element={<UAECpa />} />
                    <Route path="/uae/Solutions/IOT" element={<UAEIOT />} />
                    <Route path="/uae/Solutions/UCC" element={<UAEUCC />} />
                    <Route path="/uae/Solutions/Authentication/:vendorName" element={<UAESingleVendor />} />
                    <Route path="/uae/Solutions/CyberSecurity/:vendorName" element={<UAESingleVendor />} />
                    <Route path="/uae/Solutions/Endpoin/:vendorName" element={<UAESingleVendor />} />
                    <Route path="/uae/Solutions/InfrastructureMonitoring/:vendorName" element={<UAESingleVendor />} />
                    <Route path="/uae/Solutions/Networking/:vendorName" element={<UAESingleVendor />} />
                    <Route path="/uae/Solutions/Perimia/:vendorName" element={<UAESingleVendor />} />
                    <Route path="/uae/Solutions/Security/:vendorName" element={<UAESingleVendor />} />
                    <Route path="/uae/Solutions/ServerBackup/:vendorName" element={<UAESingleVendor />} />
                    <Route path="/uae/Solutions/AVIoT/:vendorName" element={<UAESingleVendor />} />
                    <Route path="/uae/Solutions/IOT/:vendorName" element={<UAESingleVendor />} />
                    <Route path="/uae/Solutions/UCC/:vendorName" element={<UAESingleVendor />} />

                    {/* bangladesh */}

                    <Route path="/BGD" exact element={<BGDHome />} />
                    <Route path="/BGD/About" exact element={<BGDAbout />} />
                    <Route path="/BGD/Events&News" exact element={<BGDEvents />} />
                    <Route path="/BGD/UpcomingNews" exact element={<BGDUpcomingEvents />} />
                    <Route path="/BGD/Blog" exact element={<BGDBlog />} />
                    <Route path="/BGD/ContactUs" exact element={<BGDContactUs />} />
                    <Route path="/BGD/Solutions" exact element={<BGDSolutions />} />
                    <Route path="/BGD/Portal" exact element={<BGDPortal />} />

                    <Route path="/BGD/Solutions/CyberSecurity" element={<BGDCyberSecurity />} />
                    <Route path="/BGD/Solutions/Authentication" element={<BGDAuthentication />} />
                    <Route path="/BGD/Solutions/SecurityManagement" element={<BGDSecurityManagement />} />
                    <Route path="/BGD/Solutions/Perimia" element={<BGDPerimia />} />
                    <Route path="/BGD/Solutions/EndpointSecurity" element={<BGDEndpointSecurity />} />
                    <Route path="/BGD/Solutions/Networking" element={<BGDNetworking />} />
                    <Route path="/BGD/Solutions/Infrastructure" element={<BGDInfrastructure />} />
                    <Route path="/BGD/Solutions/Server" element={<BGDServer />} />
                    <Route path="/BGD/Solutions/Microsoft" element={<BGDMicrosoft />} />
                    <Route path="/BGD/Solutions/IOT" element={<BGDIOT />} />
                    <Route path="/BGD/Solutions/UCC" element={<BGDUCC />} />
                    <Route path="/BGD/Solutions/Cpa" element={<BGDCpa />} />
                    <Route path="/BGD/Solutions/AVIoT" element={<BGDAVIoT />} />
                    <Route path="/BGD/FAQs" element={<BGDFAQs />} />
                    <Route path="/BGD/Solutions/Authentication/:vendorName" element={<BGDSingleVendor />} />
                    <Route path="/BGD/Solutions/CyberSecurity/:vendorName" element={<BGDSingleVendor />} />
                    <Route path="/BGD/Solutions/Endpoin/:vendorName" element={<BGDSingleVendor />} />
                    <Route path="/BGD/Solutions/InfrastructureMonitoring/:vendorName" element={<BGDSingleVendor />} />
                    <Route path="/BGD/Solutions/Networking/:vendorName" element={<BGDSingleVendor />} />
                    <Route path="/BGD/Solutions/Perimia/:vendorName" element={<BGDSingleVendor />} />
                    <Route path="/BGD/Solutions/Security/:vendorName" element={<BGDSingleVendor />} />
                    <Route path="/BGD/Solutions/ServerBackup/:vendorName" element={<BGDSingleVendor />} />
                    <Route path="/BGD/Solutions/IOT/:vendorName" element={<BGDSingleVendor />} />
                    <Route path="/BGD/Solutions/UCC/:vendorName" element={<BGDSingleVendor />} />
                    <Route path="/BGD/Solutions/Cpa/:vendorName" element={<BGDSingleVendor />} />
                    <Route path="/BGD/Solutions/AVIoT/:vendorName" element={<BGDSingleVendor />} />

                    <Route path="/BGD/Careers" element={<BGDCareers />} />

                    {/* Singapore */}

                    <Route path="/sg" exact element={<SGHome />} />
                    <Route path="/sg/About" exact element={<SGAbout />} />
                    <Route path="/sg/Events&News" exact element={<SGEvents />} />
                    <Route path="/sg/UpcomingNews" exact element={<SGUpcomingEvents />} />
                    <Route path="/sg/Blog" exact element={<SGBlog />} />
                    <Route path="/sg/ContactUs" exact element={<SGContactUs />} />
                    <Route path="/sg/Solutions" exact element={<SGSolutions />} />
                    <Route path="/sg/Portal" exact element={<SGPortal />} />
                    <Route path="/sg/Solutions/CyberSecurity" element={<SGCyberSecurity />} />
                    <Route path="/sg/Solutions/Authentication" element={<SGAuthentication />} />
                    <Route path="/sg/Solutions/SecurityManagement" element={<SGSecurityManagement />} />
                    <Route path="/sg/Solutions/Perimia" element={<SGPerimia />} />
                    <Route path="/sg/Solutions/EndpointSecurity" element={<SGEndpointSecurity />} />
                    <Route path="/sg/Solutions/Networking" element={<SGNetworking />} />
                    <Route path="/sg/Solutions/Infrastructure" element={<SGInfrastructure />} />
                    <Route path="/sg/Solutions/Server" element={<SGServer />} />
                    <Route path="/sg/Solutions/Microsoft" element={<SGMicrosoft />} />
                    <Route path="/sg/Solutions/IOT" element={<SGIOT />} />
                    <Route path="/sg/Solutions/UCC" element={<SGUCC />} />
                    <Route path="/sg/Solutions/Cpa" element={<SGCpa />} />
                    <Route path="/sg/Solutions/AVIoT" element={<SGAVIoT />} />
                    <Route path="/sg/FAQs" element={<SGFAQs />} />
                    <Route path="/sg/Solutions/Authentication/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Solutions/CyberSecurity/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Solutions/Endpoin/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Solutions/InfrastructureMonitoring/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Solutions/Networking/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Solutions/Perimia/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Solutions/Security/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Solutions/ServerBackup/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Solutions/IOT/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Solutions/UCC/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Solutions/Cpa/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Solutions/AVIoT/:vendorName" element={<SGSingleVendor />} />
                    <Route path="/sg/Careers" element={<SGCareers />} />


                    {/* Maldives */}

                    <Route path="/MDV" exact element={<MDVHome />} />
                    <Route path="/MDV/About" exact element={<MDVAbout />} />
                    <Route path="/MDV/Events&News" exact element={<MDVEvents />} />
                    <Route path="/MDV/UpcomingNews" exact element={<MDVUpcomingEvents />} />
                    <Route path="/MDV/Blog" exact element={<MDVBlog />} />
                    <Route path="/MDV/ContactUs" exact element={<MDVContactUs />} />
                    <Route path="/MDV/Solutions" exact element={<MDVSolutions />} />
                    <Route path="/MDV/Portal" exact element={<MDVPortal />} />

                    <Route path="/MDV/Solutions/CyberSecurity" element={<MDVCyberSecurity />} />
                    <Route path="/MDV/Solutions/Authentication" element={<MDVAuthentication />} />
                    <Route path="/MDV/Solutions/SecurityManagement" element={<MDVSecurityManagement />} />
                    <Route path="/MDV/Solutions/Perimia" element={<MDVPerimia />} />
                    <Route path="/MDV/Solutions/EndpointSecurity" element={<MDVEndpointSecurity />} />
                    <Route path="/MDV/Solutions/Networking" element={<MDVNetworking />} />
                    <Route path="/MDV/Solutions/Infrastructure" element={<MDVInfrastructure />} />
                    <Route path="/MDV/Solutions/Server" element={<MDVServer />} />
                    <Route path="/MDV/Solutions/Microsoft" element={<MDVMicrosoft />} />
                    <Route path="/MDV/Solutions/IOT" element={<MDVIOT />} />
                    <Route path="/MDV/Solutions/UCC" element={<MDVUCC />} />
                    <Route path="/MDV/Solutions/Cpa" element={<MDVCpa />} />
                    <Route path="/MDV/Solutions/AVIoT" element={<MDVAVIoT />} />
                    <Route path="/MDV/FAQs" element={<MDVFAQs />} />
                    <Route path="/MDV/Solutions/Authentication/:vendorName" element={<MDVSingleVendor />} />
                    <Route path="/MDV/Solutions/CyberSecurity/:vendorName" element={<MDVSingleVendor />} />
                    <Route path="/MDV/Solutions/Endpoin/:vendorName" element={<MDVSingleVendor />} />
                    <Route path="/MDV/Solutions/InfrastructureMonitoring/:vendorName" element={<MDVSingleVendor />} />
                    <Route path="/MDV/Solutions/Networking/:vendorName" element={<MDVSingleVendor />} />
                    <Route path="/MDV/Solutions/Perimia/:vendorName" element={<MDVSingleVendor />} />
                    <Route path="/MDV/Solutions/Security/:vendorName" element={<MDVSingleVendor />} />
                    <Route path="/MDV/Solutions/ServerBackup/:vendorName" element={<MDVSingleVendor />} />
                    <Route path="/MDV/Solutions/IOT/:vendorName" element={<MDVSingleVendor />} />
                    <Route path="/MDV/Solutions/UCC/:vendorName" element={<MDVSingleVendor />} />
                    <Route path="/MDV/Solutions/Cpa/:vendorName" element={<MDVSingleVendor />} />
                    <Route path="/MDV/Solutions/AVIoT/:vendorName" element={<MDVSingleVendor />} />

                    <Route path="/MDV/Careers" element={<MDVCareers />} />





                    {/* INDIA */}
                    <Route path="in" exact element={<INDHome />} />
                    <Route path="in/About" exact element={<INDAbout />} />
                    <Route path="in/Events&News" exact element={<INDEvents />} />
                    <Route path="in/UpcomingNews" exact element={<INDUpcomingEvents />} />
                    <Route path="in/Blog" exact element={<INDBlog />} />
                    <Route path="in/ContactUs" exact element={<INDContactUs />} />
                    <Route path="in/Solutions" exact element={<INDSolutions />} />
                    <Route path="in/Portal" exact element={<INDPortal />} />

                    <Route path="in/Solutions/CyberSecurity" element={<INDCyberSecurity />} />
                    <Route path="in/Solutions/Authentication" element={<INDAuthentication />} />
                    <Route path="in/Solutions/SecurityManagement" element={<INDSecurityManagement />} />
                    <Route path="in/Solutions/Perimia" element={<INDPerimia />} />
                    <Route path="in/Solutions/EndpointSecurity" element={<INDEndpointSecurity />} />
                    <Route path="in/Solutions/Networking" element={<INDNetworking />} />
                    <Route path="in/Solutions/Infrastructure" element={<INDInfrastructure />} />
                    <Route path="in/Solutions/Server" element={<INDServer />} />
                    <Route path="in/Solutions/Microsoft" element={<INDMicrosoft />} />
                    <Route path="in/Solutions/IOT" element={<INDIOT />} />
                    <Route path="in/Solutions/UCC" element={<INDUCC />} />
                    <Route path="in/Solutions/Cpa" element={<INDCpa />} />
                    <Route path="in/Solutions/AVIoT" element={<INDAVIoT />} />
                    <Route path="in/FAQs" element={<INDFAQs />} />
                    <Route path="in/Solutions/Authentication/:vendorName" element={<INDSingleVendor />} />
                    <Route path="in/Solutions/CyberSecurity/:vendorName" element={<INDSingleVendor />} />
                    <Route path="in/Solutions/Endpoin/:vendorName" element={<INDSingleVendor />} />
                    <Route path="in/Solutions/InfrastructureMonitoring/:vendorName" element={<INDSingleVendor />} />
                    <Route path="in/Solutions/Networking/:vendorName" element={<INDSingleVendor />} />
                    <Route path="in/Solutions/Perimia/:vendorName" element={<INDSingleVendor />} />
                    <Route path="in/Solutions/Security/:vendorName" element={<INDSingleVendor />} />
                    <Route path="in/Solutions/ServerBackup/:vendorName" element={<INDSingleVendor />} />
                    <Route path="in/Solutions/IOT/:vendorName" element={<INDSingleVendor />} />
                    <Route path="in/Solutions/UCC/:vendorName" element={<INDSingleVendor />} />
                    <Route path="in/Solutions/Cpa/:vendorName" element={<INDSingleVendor />} />
                    <Route path="in/Solutions/AVIoT/:vendorName" element={<INDSingleVendor />} />

                    <Route path="in/Careers" element={<INDCareers />} />


                </Routes>

            </>
        </Router>
    );
}

export default App;
